//
// COMPONENTS.PROGRESS-BAR
//



.c-progress-bar {
    position: relative;
    height: 5px;

    @extend %margin-bottom;
}

.c-progress-bar__filler {
    height: 100%;
}