//
// COMPONENTS.CLIENTS
//



.c-clients {
    @extend %o-list-bare;

    overflow: hidden;
    margin-right: -(spacer-x(float-box-gutter));
    margin-bottom: -(spacer-y(float-box-gutter));

    li {
        float: left;
        margin-right: spacer-x(float-box-gutter);
        margin-bottom: spacer-y(float-box-gutter);
    }

    a {
        opacity: .6;

        &:hover { opacity: 1; }
    }

    img { max-height: 30px; }
}



@include media-breakpoint-up(sm) {

    .c-clients {
        img { max-height: 50px; }
    }

}