//
// PLUGINS.NIVO-LIGHTBOX
//



//@media (-webkit-min-device-pixel-ratio: 1.3),
//       (-o-min-device-pixel-ratio: 2.6/2),
//       (min--moz-device-pixel-ratio: 1.3),
//       (min-device-pixel-ratio: 1.3),
//       (min-resolution: 1.3dppx) {
//
//    .nivo-lightbox-theme-default .nivo-lightbox-close {
//        width: 26px;
//        height: 26px;
//    }
//
//}

.nivo-lightbox-theme-default .nivo-lightbox-close {
    width: 26px;
    height: 26px;
}

.nivo-lightbox-theme-default .nivo-lightbox-title {
    font-family: $revo-font-family;
    background: none;
}