//
// OBJECTS.GRID
//



// Predefine the variables below to alter and enable specific features.

//$grid-columns:     12;
//$grid-gutter-x:    spacer-x(grid-gutter);
//$grid-gutter-y:    0;



// Grid container

.o-grid {
    lost-flex-container: row;
    //flex-direction: row;
    //lost-utility: edit;
    margin-right: -(spacer-x(grid-gutter));
    position: relative;
}



.o-grid--gallery {
    margin-right: -(spacer-x(gallery-grid-gutter));
    margin-bottom: -(spacer-y(gallery-grid-gutter));

    [class*="o-grid__col"] {
        padding-right: spacer-x(gallery-grid-gutter);
        margin-bottom: spacer-y(gallery-grid-gutter);
    }
}



// Column default style

[class*="o-grid__col"] {
    width: 100%; // Default
    padding-right: spacer-x(grid-gutter);
    margin-bottom: spacer-y(grid-gutter);
}



// Generate responsive columns

// Loop through all breakpoints
@each $breakpoint in map-keys($revo-media-breakpoints) {
    // Use breakpoint media query
    @include media-breakpoint-up($breakpoint) {
        // Generate column classes
        @for $column from 1 through $grid-columns {
            .o-grid__col-#{$breakpoint}-#{$column} {
                $fraction: ($column / $grid-columns);
                lost-column: $fraction 0 0 flex;
                //padding-right: ($grid-gutter-x * $fraction);
            }
        }
    }
}