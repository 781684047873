//
// TOOLS.RESET-TEXT
//



@mixin reset-text {
    font-family: $base-font-family;
    // We deliberately do NOT reset font-size.
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: $base-line-height;
    //text-align: left; // Fallback for where `start` is not supported
    //text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
}