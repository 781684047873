//
// OBJECTS.MEDIA
//
// Place any image- and text-like content side-by-side.
//



// Predefine the variables below to alter and enable specific features.

$revo-media-namespace:          $revo-objects-namespace !default;

$revo-media-gutter:             $revo-spacer-x !default;



.o-media {
    display: flex;
    align-items: flex-start;
}

.o-media__figure {
    margin-right: $revo-media-gutter;
}

.o-media__body {
    flex: 1;

    &,
    :last-child {
        margin-bottom: 0;
    }

}



// Reverse media object

.o-media--reverse {

    > .o-media__figure {
        order: 1;
        margin-left: $revo-media-gutter;
        margin-right: 0;
    }

}



// Flush media object have no space between figure and body

.o-media--flush {

    > .o-media__figure {
        margin-left: 0;
        margin-right: 0;
    }

}



// Media object figure set to definite width

.o-media--block {

    > .o-media__figure {
        margin-left: 0;
        margin-right: 0;
        width: spacer(figure);
    }

}