//
// PLUGINS.FONT-AWESOME
//



.fa-lg {
    font-size: 1.5em;
    line-height: 0.75em;
    vertical-align: -15%;
}