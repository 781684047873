//
// COMPONENTS.BRAND
//



.c-brand__title,
.c-brand__sub-title {
    @include reset-text;
}

.c-brand__first-word,
.c-brand__second-word {
    display: block;
}

.c-brand__sub-title {
    margin-bottom: 0;
}

.c-brand__sizer {
    font-size: 65%;
}



@include media-breakpoint-up(lg) {

    //.c-brand__sizer {
    //    font-size: 65%;
    //}

    .c-brand__title {
        margin-bottom: .8rem;
    }

}



@include media-breakpoint-up(xl) {

    .c-brand__title {
        margin-bottom: 1.4rem;
    }

    .c-brand__sizer {
        font-size: 100%;
    }

    .c-brand__sizer--sm {
        font-size: 80%;
    }

}