//
// ANIMATIONS.SCROLL
//



//.a-slide-up {
//    opacity: 0;
//    transform: translateY(20px);
//
//    &.in-view {
//        opacity: 1;
//        transition: transform 1.2s ease 0.7s,
//                    opacity 1.2s ease 0.7s;
//        transform: translateY(0);
//    }
//}



.a-progress-bar {
    width: 0;

    &.in-view {
        transition: width 1.4s ease-in-out .5s;
        // "width" is set via JavaScript.
    }
}