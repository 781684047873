//
// TRUMPS.DISPLAY
//



@each $bp in map-keys($revo-media-breakpoints) {

    @include media-breakpoint-up($bp) {
        .#{$revo-trumps-namespace}hide-#{$bp} {
            display: none !important;
        }
    }

    @include media-breakpoint-down($bp) {
        .#{$revo-trumps-namespace}show-#{$bp} {
            display: none !important;
        }
    }

}