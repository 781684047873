//
// COMPONENTS.SOCIAL-BUTTONS
//



.c-social-buttons {
    @extend %o-list-bare;

    display: flex;
    //justify-content: center;

    li {
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }
    }
}