//
// TRUMPS.SPACING
//



.u-pt-0 {
    padding-top: 0 !important;
}

.u-pb-0 {
    padding-bottom: 0 !important;
}

.u-mt-text {
    margin-top: spacer(text);
}

.u-mb-text {
    margin-bottom: spacer(text);
}