//
// REVOLT!
// GENERIC.VERTICAL-SPACING
//



// Predefine the variables below to alter and enable specific features.

$revo-vertical-space:    $revo-spacer-y !default;



// Single direction spacing with `margin-bottom`.

h1, h2, h3, h4, h5, h6,
p,
ul, ol,
dl,
blockquote,
address,
hr,
table,
fieldset,
figure,
pre,
%margin-bottom {
    margin-bottom: $revo-vertical-space;
}