//
// OBJECTS.SECTION
//



.o-section {}

.o-section__header-bg,
.o-section__content-bg {
    display: none;
}

.o-section__header {
    padding: $section-header-padding;
}

.o-section__content {
    padding: $section-content-padding;
}

.o-section__full-top-space {
    padding-top: $section-padding-y;
}

.o-section__full-bottom-space {
    padding-bottom: $section-padding-y;
}



@include media-breakpoint-up(md) {

    .o-section__header {
        padding: $section-header-padding--md;
    }

    .o-section--header .o-section__header {
        padding-bottom: 0;
    }

    .o-section__content {
        padding: $section-content-padding--md;
    }

    .o-section__full-top-space {
        padding-top: $section-padding-y--md;
    }

    .o-section__full-bottom-space {
        padding-bottom: $section-padding-y--md;
    }

}



@include media-breakpoint-up(lg) {

    .o-section {
        position: relative;
    }

    .o-section__container {
        z-index: 2;
        position: relative;
        display: flex;
    }

    .o-section__header {
        //padding: $section-header-padding--lg;
        width: 30%;
        text-align: right;
    }

    .o-section__content {
        //padding: $section-content-padding--lg;
        //margin-bottom: -$content-margin-bottom--lg;
        width: 70%;
    }

    .o-section__full-top-space {
        //padding-top: $section-padding-y--lg;
    }

    .o-section__full-bottom-space {
        //padding-bottom: $section-padding-y--lg;
    }

    // Background extension

    .o-section__header-bg,
    .o-section__content-bg {
        display: block;
        position: absolute;
        height: 100%;
        width: 50%;
        z-index: 1;
    }

    .o-section__content-bg {
        right: 0;
    }

}



@include media-breakpoint-up(xl) {

    .o-section__header {
        padding: $section-header-padding--lg;
    }

    .o-section__content {
        padding: $section-content-padding--lg;
    }

    .o-section__full-top-space {
        padding-top: $section-padding-y--lg;
    }

    .o-section__full-bottom-space {
        padding-bottom: $section-padding-y--lg;
    }

}