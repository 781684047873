//
// COMPONENTS.WORK
//



.c-work {}

.c-work__timeframe,
.c-work__heading,
.c-work__title,
.c-work__location {
    margin-bottom: .4rem;
}

.c-work__timeframe,
.c-work__title,
.c-work__location {
    font-weight: 500;
}

.c-work__timeframe,
.c-work__location {
    opacity: .6;
    font-size: $font-size--xs;
}

.c-work__title {
    font-size: $font-size--sm;
}