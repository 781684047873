//
// COMPONENTS.PRELOADER
//


.c-preloader {

    .js-enabled & {
        display: flex;
    }

    display: none;

    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}



// Source: http://stephanwagner.me/only-css-loading-spinner

.c-preloader__spinner {
    min-width: 70px;
    min-height: 70px;
}

.c-preloader__spinner:before {
    content: 'Loading…';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    margin-top: -35px;
    margin-left: -35px;
}

@keyframes c-preloader__spinner {
    to {transform: rotate(360deg);}
}

@-webkit-keyframes c-preloader__spinner {
    to {-webkit-transform: rotate(360deg);}
}

.c-preloader__spinner:not(:required):before {
    content: '';
    border-radius: 50%;
    border: 4px solid transparent;
    animation: c-preloader__spinner .8s ease infinite;
    -webkit-animation: c-preloader__spinner .8s ease infinite;
}