//
// COMPONENTS.INTRO
//



.c-intro {
    p {
        margin-bottom: spacer(text) * 2;
    }
}



@include media-breakpoint-up(sm) {

    .c-intro {
        p {
            font-size: 1.22rem;
            //line-height: 1.65;
            margin-bottom: spacer(text) * 2.5;
        }
    }

}