//
// COMPONENTS.FOOTER
//



.c-footer {}

.c-footer__contact-divider {
    margin-bottom: spacer-y(section) * 2;
}

.c-footer__brand {
    margin-bottom: spacer-y(section);
}

//.c-footer__contact {
//    margin-bottom: spacer-y(section);
//}



@include media-breakpoint-up(sm) {

    .c-footer__contact-divider {
        margin-bottom: spacer-y(section-md) * 2;
    }

    .c-footer__bottom {
        position: relative;
    }

    .c-footer__brand {
        margin-bottom: 0;
    }

    .c-footer__social-buttons {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
    }

}



@include media-breakpoint-up(md) {

    .c-footer__contact {
        margin-bottom: spacer-y(section) * .8;
    }

}



@include media-breakpoint-up(xl) {

    .c-footer__contact-divider {
        margin-bottom: spacer-y(section-lg) * 2;
    }

}