//
// SETTINGS.DEFAULTS
//



// Container

$container-max-width:    1276px;



// Typography

$root-font-size:         14px;
$base-font-size:         1rem;
$base-line-height:       1.75;
$base-font-family:       Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font-family:    $base-font-family;

$font-size--sm:          .8rem;
$font-size--xs:          .7rem;



// Grid

$grid-columns:    12;



// Spacers

$spacer:           ($base-font-size * $base-line-height);

$spacer-x:         $spacer;
$spacer-y:         $spacer;

$spacer-2-x:       ($spacer-x * 2);
$spacer-2-y:       ($spacer-y * 1.8);

$spacer-3-x:       ($spacer-x * 3);
$spacer-3-y:       ($spacer-y * 2.5);

$grid-gutter-x:    $spacer-2-x;
$grid-gutter-y:    0;

$content-margin-bottom:        $spacer-y;
$content-margin-bottom--md:    $spacer-y;
$content-margin-bottom--lg:    $spacer-y * 1.55;
//$content-margin-bottom--lg:    $spacer-3-y;

$spacers: (
    0: (
        x: 0,
        y: 0
    ),
    section: (
        x: $spacer-x,
        y: $spacer-y
    ),
    section-md: (
        x: $spacer-2-x,
        y: $spacer-2-y
    ),
    section-lg: (
        x: $spacer-3-x,
        y: $spacer-3-y
    ),
    grid-gutter: (
        x: $grid-gutter-x,
        y: 0
    ),
    gallery-grid-gutter: (
        x: $spacer-x,
        y: $spacer-y
    ),
    image-overlay-padding: (
        x: $spacer-x,
        y: $spacer-y
    ),
    float-box-gutter: (
        x: 2.5rem,
        y: 1.5rem
    ),
    content:    $content-margin-bottom,
    content-md: $content-margin-bottom--md,
    content-lg: $content-margin-bottom--lg,
    text:       .55rem,
    figure:     2.8rem
);



// Template spacing controls

$section-padding-y:              $spacer-y;
$section-padding-x:              $spacer-x;
$section-padding-bottom:         ($section-padding-y - $content-margin-bottom);
$section-padding:                $section-padding-y  $section-padding-x  $section-padding-bottom;
$section-header-padding:         $section-padding;
$section-content-padding:        $section-padding;

$section-padding-y--md:          $spacer-2-y;
$section-padding-x--md:          $spacer-2-x;
$section-padding-bottom--md:     ($section-padding-y--md - $content-margin-bottom--md);
$section-padding--md:            $section-padding-y--md  $section-padding-x--md  $section-padding-bottom--md;
$section-header-padding--md:     $section-padding--md;
$section-content-padding--md:    $section-padding--md;

$section-padding-y--lg:          $spacer-3-y;
$section-padding-x--lg:          $spacer-3-x;
$section-padding-bottom--lg:     ($section-padding-y--lg - $content-margin-bottom--lg);
$section-padding--lg:            $section-padding-y--lg  $section-padding-x--lg  $section-padding-bottom--lg;
$section-header-padding--lg:     $section-padding--lg;
$section-content-padding--lg:    $section-padding--lg;
