//
// COMPONENTS.MAIN-CONTAINER
//



.js-enabled .js-main-container {
    display: none;
}



.c-main-container {
    // Fixes issue with too much space on the right of the grid on small screens
    overflow-x: hidden;
}



//.c-main-container {
//    //max-width: 1300px;
//    margin: 0 auto;
//    padding: 1.366rem;
//
//    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+55,d8d8d8+100 */
//    background: #eaeaea; /* Old browsers */
//    background: -moz-linear-gradient(-45deg,  #eaeaea 55%, #d8d8d8 100%); /* FF3.6-15 */
//    background: -webkit-linear-gradient(-45deg,  #eaeaea 55%,#d8d8d8 100%); /* Chrome10-25,Safari5.1-6 */
//    background: linear-gradient(135deg,  #eaeaea 55%,#d8d8d8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#d8d8d8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//
//    background-attachment: fixed;
//}