//
// OBJECTS.CONTENT
//



.o-content {
    padding-bottom: spacer(content);
}

.o-content__body {

    &,
    :last-child {
        margin-bottom: 0;
        //padding-bottom: 0;
    }
}



@include media-breakpoint-up(md) {

    .o-content {
        padding-bottom: spacer(content-md);
    }

}



@include media-breakpoint-up(xl) {

    .o-content {
        padding-bottom: spacer(content-lg);
    }

}