//
// COMPONENTS.IMAGE-OVERLAY
//



.c-image-overlay {
    position: relative;
    display: block;
    overflow: hidden;

    img {
        width: 100%;
        transition: ease .6s;
    }

    .c-image-overlay__deco-line {
        height: 3px;
        width: 0;
        margin-top: spacer(text) * 1.5;
        margin-bottom: spacer(text) * 1.5;
        text-align: left;
        transition: width .65s ease-out;
    }

    &:hover {

        img {
            transition: ease .8s;
            transform: scale(1.012);
        }

        .c-image-overlay__deco-line {
            width: 15%;
        }

    }
}

.c-image-overlay__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 375px;
    opacity: 0;
    padding: spacer-y(image-overlay-padding)  spacer-x(image-overlay-padding);
    transition: ease .6s;

    .c-image-overlay:hover & {
        opacity: 1;
    }
}