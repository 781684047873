//
// BASE.ROOT
//



html,
body {
    height: 100%;
}

body {
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
}