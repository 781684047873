//
// REVOLT!
// GENERIC.RESET
//



////////////////////////////////////////////////////////////////////////////////
// Remove margins and paddings.
////////////////////////////////////////////////////////////////////////////////



// As well as using normalize.css, it is often advantageous to remove all
// margins from certain elements.

body,
h1, h2, h3, h4, h5, h6,
p,
blockquote,
pre,
dl, dd,
ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
    margin:  0;
    padding: 0;
}



////////////////////////////////////////////////////////////////////////////////
// Misc
////////////////////////////////////////////////////////////////////////////////



// Give a help cursor to elements that give extra info on `:hover`.

abbr[title],
dfn[title] {
    cursor: help;
}

// Override browser default `font-style: italic`.

address {
    font-style: normal;
}

// Remove extra vertical spacing in nested lists.

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

// 1. Offset `alt` text from surrounding copy.
// 2. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.

img {
    font-style: italic; // [1]
    vertical-align: middle; // [2]
}



////////////////////////////////////////////////////////////////////////////////
// Forms
////////////////////////////////////////////////////////////////////////////////



// Set `display: inline-block` to allow labels to use `margin` for spacing.

label {
    display: inline-block;
}

// 1. Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc
//    are properly inherited. However, `line-height` isn't addressed there.
//    Using this ensures we don't need to unnecessarily redeclare the global
//    font stack.
// 2. iOS adds rounded borders by default, so let's deactivate them.

input,
button,
select,
textarea {
    line-height: inherit; // [1]
    border-radius: 0; // [2]
}

// Textareas should really only resize vertically so they don't break their
// (horizontal) containers.

textarea {
    resize: vertical;
}

// 1. Chrome and Firefox set a `min-width: min-content;` on fieldsets,
//    so we reset that to ensure it behaves more like a standard block element.
//    See: https://github.com/twbs/bootstrap/issues/12359
// 2. Remove Normalize's border.

fieldset {
    min-width: 0; // [1]
    border: 0; // [2]
}

// Reset the entire legend element to match the `fieldset`

legend {
    display: block;
    width: 100%;
    line-height: inherit;
}

// 1. Undo Normalize's default here to match our own box-sizing.
// 2. Override the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot
//    simply be added to `.form-control` as it's not specific enough.
//    For details, see https://github.com/twbs/bootstrap/issues/11586.

input[type="search"] {
    box-sizing: inherit; // [1]
    -webkit-appearance: none; // [2]
}