//
// COMPONENTS.DECO-LINE
//



.c-deco-line {
    height: 5px;
    width: 30%;
    margin-top: spacer(text);
    margin-bottom: (spacer(text) * 1.7);
    text-align: left;
}