//
// COMPONENTS.HEADER
//



.c-header {}

.c-header__top,
.c-header__brand,
.c-header__contact-divider {
    margin-bottom: spacer-y(section);
}

.c-header__brand,
.c-header__avatar,
.c-header__contact {
    text-align: center;
}

.c-header__social-buttons {
    justify-content: center;
}



@include media-breakpoint-up(md) {

    .c-header__top,
    .c-header__brand {
        margin-bottom: spacer-y(section);
    }

    .c-header__content {
        padding-top: spacer-y(section);
    }

    .c-header__contact {
        text-align: left;
    }

}



@include media-breakpoint-up(lg) {

    .c-header__header,
    .c-header__content {
        height: 100vh;
    }

    .c-header__inner-header,
    .c-header__inner-content {
        position: relative;
        height: 100%;

        // Center vertically
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .c-header__avatar,
    .c-header__top {
        top: -2vh;
        position: relative;
    }

    .c-header__avatar {
        margin-top: -5.8rem;
        text-align: right;
        position: relative;
    }

    /* IE11 hack */
    *::-ms-backdrop, .c-header__avatar { height: 18vw; }

    .c-header__brand {
        text-align: left;
        margin-bottom: 0;
    }

    .c-header__social-buttons {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .c-header__contact {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }

}